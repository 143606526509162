import React from "react";

import { Field, FieldArray } from "formik";

import ActionParcelSubtractableAreasSection from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import SubtractableAreaWithRadioButton from "../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton";

import {
  RestrictionTo,
  RestrictionType,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  isEditing: boolean;
  maxValue: number;
  onParcelSubtractionDelete: (type: string, value: number) => void;
  parcelIndex: number;
};

const WaterParcelSubtractableAreas = ({
  isEditing,
  maxValue,
  onParcelSubtractionDelete,
  parcelIndex,
}: Props) => (
  <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.water`}>
    {({ form, push, remove }) => {
      const handleSubtractionDelete = (type: string, value: number) => {
        const index = form.values.parcels[
          parcelIndex
        ].subtractableAreas.water.findIndex(
          (sa: RestrictionTo) => sa.value === value,
        );
        // if delete checked value, set checked value to 0
        if (
          form.values.parcels[parcelIndex].subtractableAreas.waterChecked ===
          form.values.parcels[parcelIndex].subtractableAreas.water[index].value
        ) {
          form.setFieldValue(
            `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
            0,
          );
        }
        remove(index);
        onParcelSubtractionDelete(type, value);
      };

      const handleAddSubtractableArea = (sa: RestrictionTo) => {
        push(sa);
        form.setFieldValue(
          `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
          sa.value,
        );
      };

      return (
        <ActionParcelSubtractableAreasSection
          handleAdd={handleAddSubtractableArea}
          isEditing={isEditing}
          maxValue={maxValue}
          subtractableAreaType={RestrictionType.Water}
          resetSubtractableAreasWarning={() => {
            form.setFieldError(
              `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
              undefined,
            );
          }}
          setSubtractableAreasWarning={() => {
            form.setFieldError(
              `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
              "Selected value is lower than the maximum value added from POR",
            );
          }}
          subtractableAreas={
            form.values.parcels[parcelIndex].subtractableAreas.water
          }
          subtractableChecked={
            form.values.parcels[parcelIndex].subtractableAreas.waterChecked
          }
        >
          <Field
            component={SubtractableAreaWithRadioButton}
            handleSubtractionDelete={handleSubtractionDelete}
            isEditing={isEditing}
            name={`parcels.${parcelIndex}.subtractableAreas.waterChecked`}
            options={form.values.parcels[parcelIndex].subtractableAreas.water}
            subtractableAreaType={RestrictionType.Water}
          />
        </ActionParcelSubtractableAreasSection>
      );
    }}
  </FieldArray>
);

export default WaterParcelSubtractableAreas;
