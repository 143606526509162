import * as React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getMainMapTelematicsDashboardMachinesOrder,
  getMainMapTelematicsDashboardMachinesOrderBy,
} from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";

import { NAMESPACE } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.reducer";

import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../shared/misc/helper";

import { MachineTableRow } from "./MachineTableRow";
import { useMachineTable } from "./useMachineTable";
import { useMapSelection } from "./useMapSelection";

import { PositionDashboard } from "../../../../shared/api/telematics/telematics.types";

const COLUMNS = {
  STATE: getColDesc(true, <></>, {
    display: "grid",
    placeItems: "center",
    // maxWidth: 1,
    padding: "9px 0",
    height: "inherit",
    width: 40,
    paddingLeft: 10,
  }),
  MACHINE_NAME: getColDesc(
    true,
    <FormattedMessage id="MainMapTelematics.machinesDashboard.columns.machines" />,
    { width: "100%" },
  ),
};

const MINIMAL_BOTTOM_PANEL_HEIGHT = 120;

type Props = {
  machines: PositionDashboard[];
};

const MachinesTable = ({ machines }: Props) => {
  const order = useSelector(getMainMapTelematicsDashboardMachinesOrder);
  const orderBy = useSelector(getMainMapTelematicsDashboardMachinesOrderBy);

  const { bottomPanelHeight, tableContainerRef, topOffset } = useMachineTable(
    !!machines.length,
  );
  useMapSelection(machines);

  const classes = useDynamicStyles(
    window.innerHeight,
    bottomPanelHeight || MINIMAL_BOTTOM_PANEL_HEIGHT,
    topOffset,
  );

  return (
    <TableContainer
      className={classes.paper}
      component={Paper}
      ref={tableContainerRef}
    >
      <Table className={classes.noBorder}>
        <CfTableHead
          columns={COLUMNS}
          namespace={NAMESPACE}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {machines.map((machine, i) => (
            <MachineTableRow i={i} key={i} machines={machines} row={machine} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useDynamicStyles = (
  screenHeight: number,
  bottomPanelHeight: number,
  topOffset: number,
) => {
  const useStyles = makeStyles(() => ({
    paper: {
      paddingBottom: 10,
      maxHeight: screenHeight - bottomPanelHeight - topOffset,
      overflowY: "auto",
    },
    noBorder: {
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  }));
  return useStyles();
};

export { MachinesTable };
