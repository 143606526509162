import React, { PropsWithChildren } from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";

import errorPage40x from "../../../../assets/img/error-page-404.svg";
import errorPage500 from "../../../../assets/img/error-page-500.svg";
import errorPage503 from "../../../../assets/img/error-page-503.svg";
import { RsaaApiError } from "../../../../types";

const images = {
  0: "",
  404: errorPage40x,
  500: errorPage500,
  503: errorPage503,
};

type Props = {
  handle400s?: boolean;
  error40xHeadingTranslId?: string;
  error40xMessageTranslId?: string;
  error?: Partial<RsaaApiError>;
};

const CfErrorPage = ({
  children,
  error = {},
  error40xHeadingTranslId = "",
  error40xMessageTranslId = "",
  handle400s = false,
}: PropsWithChildren<Props>) => {
  const classes = useStyle();
  const { code } = error;
  const parsedCode = isNil(code)
    ? (0 as unknown as keyof typeof images)
    : (code as keyof typeof images);

  const isError40x = /40[0-4]/.test(parsedCode.toString());

  return !(
    !!error.isError &&
    (error.code === 500 || error.code === 503 || (isError40x && handle400s))
  ) ? (
    <>{children}</>
  ) : (
    <div className={classes.wrapper}>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <img
            alt={`Error ${code}`}
            className={classes.image}
            src={images[isError40x ? 404 : parsedCode]}
          />
        </Grid>
        <Grid item>
          <h1 className={classes.heading} data-test="error-title">
            <FormattedMessage
              id={
                isError40x ? error40xHeadingTranslId : `error.heading.${code}`
              }
            />
          </h1>
        </Grid>
        {!isError40x && (
          <Grid item style={{ textAlign: "center" }} xs={8}>
            <FormattedMessage id={`error.message.${code}`} />{" "}
            <a href="mailto:help@cleverfarm.cz">help@cleverfarm.cz</a>.
          </Grid>
        )}
        {isError40x && (
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={0}
          >
            <Grid item style={{ textAlign: "center" }} xs={12}>
              <FormattedMessage id={error40xMessageTranslId} />
            </Grid>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => history.back()}
              size="medium"
              variant="contained"
            >
              <FormattedMessage id="common.back" />
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  image: {
    width: 250,
    height: "auto",
  },
  heading: {
    marginBottom: 10,
  },
  button: {
    marginTop: 40,
  },
}));

export default CfErrorPage;
