import React from "react";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { setTablePage, setTableRowsPerPage } from "../../actions/table.actions";

type Props = {
  count?: number;
  page: number;
  rowsPerPage: number;
  namespace?: string;
};

const CfTableFooter = ({ count = 0, namespace, page, rowsPerPage }: Props) => {
  const dispatch = useDispatch();

  const handleChangeRowsPerPage = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(setTableRowsPerPage(evt.target.value, namespace));
  };

  const handleChangePage = (
    evt: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ) => {
    dispatch(setTablePage(page, namespace));
  };

  const rowsPerPageOptions = [10, 25, 50, 100];

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          data-test="table-pagination"
          labelRowsPerPage={<FormattedMessage id="common.rowsPerPage" />}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={(setup) => (
            <FormattedMessage
              id="common.currentPage"
              values={{
                from: setup.from,
                to: setup.to,
                count: setup.count,
              }}
            />
          )}
        />
      </TableRow>
    </TableFooter>
  );
};

export default CfTableFooter;
