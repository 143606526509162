import React, { Fragment, Component } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  TableBody,
  TableRow,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { withStyles } from "@mui/styles";
import head from "lodash/head";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field } from "redux-form";

import {
  updateVariableExpenseZoneDose,
  updateVariableExpenseSumValues,
} from "../../../shared/actions/actions.actions";

import { indicesList } from "../../../../../core/precision/selectors/indices";
import { getFertilizerDoseApi } from "../../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfLoadingPlaceholder from "../../../../../shared/components/common/CfLoadingPlaceholder/CfLoadingPlaceholder";
import SelectionItemColumn from "../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn";
import SelectionItemHeading from "../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading";
import CfFormattedField from "../../../../../shared/components/form/CfFormattedField/CfFormattedField";
import CfFormattedTextField from "../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfReduxFormSelect from "../../../../../shared/components/form/CfReduxFormSelect/CfReduxFormSelect";
import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { ManagementZoneIcon } from "../../../../../shared/icons/ManagementZoneIcon";
import { getColDesc } from "../../../../../shared/misc/helper";
import * as validators from "../../../../../shared/misc/validators";
import { zoneTotalDoseChanged } from "../../../shared/misc/action.helpers";
import incorporationDaysSource from "../../helpers/incorporationDay.json";

class VrfExpenseCard extends Component {
  constructor(props) {
    super(props);

    const {
      expense: { doseUnit },
    } = props;

    this.columns = {
      map: getColDesc(
        false,
        <span style={{ paddingLeft: 15 }}>
          <FormattedMessage id="common.map" />
        </span>,
      ),
      zoneArea: getColDesc(
        false,
        <Fragment>
          <FormattedMessage id="VariableFertilization.zones-area" /> (ha)
        </Fragment>,
      ),
      zoneQuality: getColDesc(
        false,
        <Fragment>
          <FormattedMessage id="VariableFertilization.zones-quality" /> (%)
        </Fragment>,
      ),
      dose: getColDesc(
        false,
        <Fragment>
          <FormattedMessage id="common.dose" /> ({doseUnit.id}/ha)
        </Fragment>,
      ),
      totalDose: getColDesc(
        false,
        <Fragment>
          <FormattedMessage id="common.totalDose" /> ({doseUnit.id})
        </Fragment>,
      ),
      n: getColDesc(
        false,
        <Fragment>
          <FormattedMessage id="VariableFertilization.accountable-N" /> (kg/ha)
        </Fragment>,
      ),
      p: getColDesc(
        false,
        <Fragment>
          <span>
            P<sub>2</sub>O<sub>5</sub>
          </span>{" "}
          (kg/ha)
        </Fragment>,
      ),
      k: getColDesc(
        false,
        <Fragment>
          <span>
            K<sub>2</sub>O
          </span>{" "}
          (kg/ha)
        </Fragment>,
      ),
    };
  }

  componentDidUpdate(prevProps) {
    const { isFetching } = this.props;

    if (!isFetching) {
      const {
        expense: {
          doseUnit,
          material: { id },
          variableExpense: { applicationZones: newZones, areaHa },
        },
        formName,
        index: expenseIndex,
      } = this.props;
      const {
        expense: { variableExpense: oldVariableExpense },
      } = prevProps;
      const oldZones = oldVariableExpense?.applicationZones;

      if (
        newZones?.length &&
        oldZones?.length &&
        zoneTotalDoseChanged(oldZones, newZones)
      ) {
        const sumTotalDose = newZones.reduce(
          (acc, zone) => acc + zone.totalDose,
          0,
        );
        const averageDose = sumTotalDose / areaHa;
        this.props
          .getFertilizerDoseApi(id, doseUnit.id, averageDose)
          .then((res) => {
            if (res.payload) {
              this.props.updateVariableExpenseSumValues(
                res.payload,
                expenseIndex,
                formName,
                sumTotalDose,
                averageDose,
              );
            }
          });
      }
    }
  }

  onDoseChange = (newValue, areaHa, zoneIndex) => {
    const value = parseFloat(newValue);

    if (value && !isNaN(value)) {
      const {
        expense: {
          doseUnit,
          material: { id },
        },
        formName,
        index: expenseIndex,
      } = this.props;
      this.props.getFertilizerDoseApi(id, doseUnit.id, value).then((res) => {
        if (res.payload) {
          const totalDose = value * areaHa;
          this.props.updateVariableExpenseZoneDose(
            res.payload,
            totalDose,
            expenseIndex,
            zoneIndex,
            formName,
          );
        }
      });
    }
  };

  renderTdZoneArea = (zone, satelliteType) => {
    const { classes, isFetching } = this.props;
    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name="zoneArea">
        {isFetching ? (
          <CfLoadingPlaceholder width={105} />
        ) : (
          <div className={classes.tdContent}>
            <span className={classes.zoneAreaIcon}>
              <ManagementZoneIcon
                color={zone.color || "#cccccc"}
                rx={4}
                size={16}
                useStroke={
                  (zone.zoneId === 1 || zone.zoneId === 2) &&
                  indicesList.map((i) => i.id).includes(satelliteType)
                }
              />
            </span>
            <span className={`${classes.zoneArea}`}>
              <CfFormattedNumber value={zone.areaHa} />
            </span>
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdDose = (zone, expenseIndex, zoneIndex) => {
    const { classes, isEditing, isFetching } = this.props;
    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name="dose">
        {isFetching ? (
          <CfLoadingPlaceholder width={105} />
        ) : (
          <div className={classes.tdContent}>
            <CfFormattedField
              component={CfFormattedTextField}
              disabled={!isEditing}
              FormHelperTextProps={{ className: classes.doseHelperText }}
              fullWidth
              name={`expenses[${expenseIndex}].variableExpense.applicationZones[${zoneIndex}].doseHa`}
              type="text"
              validate={[validators.required, validators.zeroPositiveNumber]}
              InputLabelProps={{
                style: styles.inputLabel,
              }}
              onBlur={(evt, newValue) =>
                this.onDoseChange(newValue, zone.areaHa, zoneIndex)
              }
            />
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdValue = (name, value, width = 105) => {
    const { classes, isFetching } = this.props;
    return (
      <CfTableCell
        classes={{ root: `${classes.tdValue} ${classes.tdPaddingDense}` }}
        name={name}
      >
        {isFetching ? (
          <CfLoadingPlaceholder width={width} />
        ) : (
          <div className={classes.tdContent}>
            <CfFormattedNumber value={value} />
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdFooter = (name, label, value, width = 105) => {
    const { classes, isFetching } = this.props;

    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name={name}>
        {isFetching ? (
          <CfLoadingPlaceholder width={width} />
        ) : (
          <div>
            <div className={classes.tfLabel}>{label}</div>

            <div className={classes.tfContent}>
              <div className={classes.tdContent}>
                {value !== undefined ? (
                  <CfFormattedNumber value={value} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        )}
      </CfTableCell>
    );
  };

  render() {
    const {
      classes,
      currMapZones,
      expense: { doseUnit, material, variableExpense },
      index,
      isEditing,
      isFetching,
      langId,
      onMapClick,
      onRemove,
      satellite,
    } = this.props;
    const { incorporationDays } = incorporationDaysSource;
    const zones = !isFetching
      ? variableExpense.applicationZones
      : [{ zoneId: "a" }, { zoneId: "b" }, { zoneId: "c" }];
    return (
      <Paper className={classes.paper} data-test="variable-expense-card">
        <CardContent classes={{ root: `${classes.wrapper} ${classes.header}` }}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={0}
          >
            <Grid item sm={11} xs={10}>
              <Grid item xs={12}>
                <SelectionItemHeading customClasses={{ item: classes.heading }}>
                  <span className={classes.href}>
                    {material?.name} -{" "}
                    <FormattedMessage id="VariableFertilization.variable-application" />
                  </span>
                </SelectionItemHeading>
              </Grid>

              <Grid item sm={6} xs={12}>
                <CfFormControl classes={{ formControl: classes.formControl }}>
                  <InputLabel className={classes.inputLabel}>
                    <FormattedMessage id="SoilIncorporation.incorporation.date" />
                  </InputLabel>
                  <Field
                    component={CfReduxFormSelect}
                    disabled={!isEditing}
                    name={`expenses[${index}].incorporationDay`}
                  >
                    {incorporationDays.map((dat) => (
                      <MenuItem
                        className={classes.menuItem}
                        key={dat.code}
                        value={dat.code}
                      >
                        <FormattedMessage id={dat.intId} />
                      </MenuItem>
                    ))}
                  </Field>
                </CfFormControl>
              </Grid>
            </Grid>

            <Grid className={classes.removeButtonDiv} item sm={1} xs={2}>
              <IconButton
                aria-label="Remove item"
                className={classes.removeButton}
                disabled={!isEditing || isFetching}
                onClick={onRemove}
                size="large"
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>

        <CfTableWrapper customClasses={{ table: classes.table }}>
          <CfTableHead columns={this.columns} langId={langId} />
          <TableBody>
            <TableRow key={head(zones).zoneId}>
              <CfTableCell
                classes={{ root: `${classes.tdMap} ${classes.tdPaddingDense}` }}
                name="map"
                rowSpan={zones.length}
              >
                {isFetching ? (
                  <CfLoadingPlaceholder
                    classes={{ placeholderItem: classes.map }}
                    width={150}
                  />
                ) : (
                  <a
                    href="#"
                    onClick={() =>
                      onMapClick(currMapZones === zones ? null : zones)
                    }
                  >
                    <CfStaticMap
                      geometries={zones}
                      isSelected={currMapZones === zones}
                      mapId={`expense-card-map_${index}`}
                      classes={{
                        map: classes.map,
                      }}
                    />
                  </a>
                )}
              </CfTableCell>
              {this.renderTdZoneArea(head(zones), satellite.type)}
              {this.renderTdValue("quality", head(zones).quality)}
              {this.renderTdDose(head(zones), index, 0)}
              {this.renderTdValue("totalDose", head(zones).totalDose)}
              {this.renderTdValue("accountableN", head(zones).accountableN)}
              {this.renderTdValue("p", head(zones).p)}
              {this.renderTdValue("k", head(zones).k)}
            </TableRow>

            {zones.slice(1).map((z, zoneIndex) => (
              <TableRow key={z.zoneId}>
                {this.renderTdZoneArea(z, satellite.type)}
                {this.renderTdValue("quality", z.quality)}
                {this.renderTdDose(z, index, zoneIndex + 1)}
                {this.renderTdValue("totalDose", z.totalDose)}
                {this.renderTdValue("accountableN", z.accountableN)}
                {this.renderTdValue("p", z.p)}
                {this.renderTdValue("k", z.k)}
              </TableRow>
            ))}

            <TableRow className={`${classes.tableRow} ${classes.header}`}>
              <CfTableCell
                classes={{ root: classes.tdPaddingDense }}
                name="map"
              >
                <SelectionItemColumn
                  customClasses={{ column: classes.tfTotal }}
                >
                  <FormattedMessage id="VariableFertilization.total" />
                </SelectionItemColumn>
              </CfTableCell>

              {this.renderTdFooter(
                "zoneArea",
                <span>
                  <FormattedMessage id="VariableFertilization.total-zone-area" />{" "}
                  (ha)
                </span>,
                variableExpense?.areaHa,
              )}

              {this.renderTdFooter(
                "zoneQuality",
                <span>
                  <FormattedMessage id="VariableFertilization.avg-zone-quality" />{" "}
                  (%)
                </span>,
                variableExpense?.averageQuality,
              )}

              {this.renderTdFooter(
                "dose",
                <span>
                  <FormattedMessage id="common.avg-dose" /> ({doseUnit.id}/ha)
                </span>,
                variableExpense?.doseHa,
              )}

              {this.renderTdFooter(
                "totalDose",
                <span>
                  <FormattedMessage id="common.total-dose-per-parcel" /> (
                  {doseUnit.id})
                </span>,
                variableExpense?.totalDose,
              )}

              {this.renderTdFooter(
                "accountableN",
                <span>
                  <FormattedMessage id="VariableFertilization.accountable-N" />{" "}
                  (kg/ha)
                </span>,
                variableExpense?.accountableN,
              )}

              {this.renderTdFooter(
                "p",
                <span>
                  <span>
                    P<sub>2</sub>O<sub>5</sub>
                  </span>{" "}
                  (kg/ha)
                </span>,
                variableExpense?.p,
              )}

              {this.renderTdFooter(
                "k",
                <span>
                  <span>
                    K<sub>2</sub>O
                  </span>{" "}
                  (kg/ha)
                </span>,
                variableExpense?.k,
              )}
            </TableRow>
          </TableBody>
        </CfTableWrapper>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFertilizerDoseApi,
      updateVariableExpenseZoneDose,
      updateVariableExpenseSumValues,
    },
    dispatch,
  );

VrfExpenseCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expense: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onMapClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  getFertilizerDoseApi: PropTypes.func.isRequired,
  updateVariableExpenseZoneDose: PropTypes.func.isRequired,
  updateVariableExpenseSumValues: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  satellite: PropTypes.object,
  currMapZones: PropTypes.array,
};

VrfExpenseCard.defaultProps = {
  currMapZones: null,
  satellite: null,
};

const styles = (theme) => ({
  paper: {
    margin: "10px 0",
    position: "relative",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "10px 18px !important",
    backgroundColor: theme.palette.common.white,
  },
  header: {
    backgroundColor: theme.palette.grey[100],
  },
  heading: {
    margin: "2px 0 5px 0",
  },
  removeButton: {
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
  href: {
    color: "#88B04B",
    "&:focus": {
      outline: "none",
      color: "#88B04B",
    },
    "&:hover": {
      color: "#88B04B",
    },
  },
  formControl: {
    margin: 0,
  },
  col: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  removeButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  map: {
    height: "150px",
    width: "150px",
    margin: "0 15px",
  },
  tdPaddingDense: {
    padding: "15px 20px 15px 6px",
  },
  tdMap: {
    width: "150px",
  },
  zoneAreaIcon: {
    top: "2px",
    position: "relative",
  },
  zoneArea: {
    marginLeft: 5,
  },
  inputLabel: {
    fontWeight: "inherit",
  },
  doseHelperText: {
    position: "absolute",
    top: 25,
  },
  tdValue: {
    margin: "0 10px",
  },
  tdContent: {
    fontSize: "16px",
    width: "105px",
    display: "flex",
    alignItems: "center",
  },
  tfLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    height: "36px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  tfContent: {
    fontSize: 16,
  },
  tfTotal: {
    padding: "2px 6px 2px 15px",
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(VrfExpenseCard);
