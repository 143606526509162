const PREFIX = "AGROEVIDENCE_CATALOGUES";

export const GET_PLANT_PROTECTIONS_REQUEST = `${PREFIX}/GET_PLANT_PROTECTIONS_REQUEST`;
export const GET_PLANT_PROTECTIONS_SUCCESS = `${PREFIX}/GET_PLANT_PROTECTIONS_SUCCESS`;
export const GET_PLANT_PROTECTIONS_ERROR = `${PREFIX}/GET_PLANT_PROTECTIONS_ERROR`;
export const RESET_PLANT_PROTECTIONS = `${PREFIX}/RESET_PLANT_PROTECTIONS`;

export const GET_PLANT_PROTECTION_REQUEST = `${PREFIX}/GET_PLANT_PROTECTION_REQUEST`;
export const GET_PLANT_PROTECTION_SUCCESS = `${PREFIX}/GET_PLANT_PROTECTION_SUCCESS`;
export const GET_PLANT_PROTECTION_ERROR = `${PREFIX}/GET_PLANT_PROTECTION_ERROR`;
export const RESET_PLANT_PROTECTION = `${PREFIX}/RESET_PLANT_PROTECTION`;

export const COPY_PLANT_PROTECTION_REQUEST = `${PREFIX}/COPY_PLANT_PROTECTION_REQUEST`;
export const COPY_PLANT_PROTECTION_SUCCESS = `${PREFIX}/COPY_PLANT_PROTECTION_SUCCESS`;
export const COPY_PLANT_PROTECTION_ERROR = `${PREFIX}/COPY_PLANT_PROTECTION_ERROR`;

export const UPDATE_PLANT_PROTECTION_REQUEST = `${PREFIX}/UPDATE_PLANT_PROTECTION_REQUEST`;
export const UPDATE_PLANT_PROTECTION_SUCCESS = `${PREFIX}/UPDATE_PLANT_PROTECTION_SUCCESS`;
export const UPDATE_PLANT_PROTECTION_ERROR = `${PREFIX}/UPDATE_PLANT_PROTECTION_ERROR`;

export const CREATE_PLANT_PROTECTION_REQUEST = `${PREFIX}/CREATE_PLANT_PROTECTION_REQUEST`;
export const CREATE_PLANT_PROTECTION_SUCCESS = `${PREFIX}/CREATE_PLANT_PROTECTION_SUCCESS`;
export const CREATE_PLANT_PROTECTION_ERROR = `${PREFIX}/CREATE_PLANT_PROTECTION_ERROR`;

export const GET_PLANT_PROTECTION_APPLICATIONS_REQUEST = `${PREFIX}/GET_PLANT_PROTECTION_APPLICATIONS_REQUEST`;
export const GET_PLANT_PROTECTION_APPLICATIONS_SUCCESS = `${PREFIX}/GET_PLANT_PROTECTION_APPLICATIONS_SUCCESS`;
export const GET_PLANT_PROTECTION_APPLICATIONS_ERROR = `${PREFIX}/GET_PLANT_PROTECTION_APPLICATIONS_ERROR`;

export const CREATE_PLANT_PROTECTION_APPLICATION_REQUEST = `${PREFIX}/CREATE_PLANT_PROTECTION_APPLICATION_REQUEST`;
export const CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS = `${PREFIX}/CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS`;
export const CREATE_PLANT_PROTECTION_APPLICATION_ERROR = `${PREFIX}/CREATE_PLANT_PROTECTION_APPLICATION_ERROR`;

export const UPDATE_PLANT_PROTECTION_APPLICATION_REQUEST = `${PREFIX}/UPDATE_PLANT_PROTECTION_APPLICATION_REQUEST`;
export const UPDATE_PLANT_PROTECTION_APPLICATION_SUCCESS = `${PREFIX}/UPDATE_PLANT_PROTECTION_APPLICATION_SUCCESS`;
export const UPDATE_PLANT_PROTECTION_APPLICATION_ERROR = `${PREFIX}/UPDATE_PLANT_PROTECTION_APPLICATION_ERROR`;

export const DELETE_PLANT_PROTECTION_APPLICATION_REQUEST = `${PREFIX}/DELETE_PLANT_PROTECTION_APPLICATION_REQUEST`;
export const DELETE_PLANT_PROTECTION_APPLICATION_SUCCESS = `${PREFIX}/DELETE_PLANT_PROTECTION_APPLICATION_SUCCESS`;
export const DELETE_PLANT_PROTECTION_APPLICATION_ERROR = `${PREFIX}/DELETE_PLANT_PROTECTION_APPLICATION_ERROR`;

export const GET_ACTIVE_SUBSTANCES_REQUEST = `${PREFIX}/GET_ACTIVE_SUBSTANCES_REQUEST`;
export const GET_ACTIVE_SUBSTANCES_SUCCESS = `${PREFIX}/GET_ACTIVE_SUBSTANCES_SUCCESS`;
export const GET_ACTIVE_SUBSTANCES_ERROR = `${PREFIX}/GET_ACTIVE_SUBSTANCES_ERROR`;
export const RESET_ACTIVE_SUBSTANCES = `${PREFIX}/RESET_ACTIVE_SUBSTANCES`;

export const GET_ORGANISMS_REQUEST = `${PREFIX}/GET_ORGANISMS_REQUEST`;
export const GET_ORGANISMS_SUCCESS = `${PREFIX}/GET_ORGANISMS_SUCCESS`;
export const GET_ORGANISMS_ERROR = `${PREFIX}/GET_ORGANISMS_ERROR`;
export const RESET_ORGANISMS = `${PREFIX}/RESET_ORGANISMS`;

export const GET_BIO_FUNCTION_REQUEST = `${PREFIX}/GET_BIO_FUNCTION_REQUEST`;
export const GET_BIO_FUNCTION_SUCCESS = `${PREFIX}/GET_BIO_FUNCTION_SUCCESS`;
export const GET_BIO_FUNCTION_ERROR = `${PREFIX}/GET_BIO_FUNCTION_ERROR`;
export const RESET_BIO_FUNCTION = `${PREFIX}/RESET_BIO_FUNCTION`;

export const GET_POR_APPLICATIONS_REQUEST = `${PREFIX}/GET_POR_APPLICATIONS_REQUEST`;
export const GET_POR_APPLICATIONS_SUCCESS = `${PREFIX}/GET_POR_APPLICATIONS_SUCCESS`;
export const GET_POR_APPLICATIONS_ERROR = `${PREFIX}/GET_POR_APPLICATIONS_ERROR`;
export const REMOVE_POR_APPLICATION = `${PREFIX}/REMOVE_PLANT_PROTECTION_APPLICATION`;
