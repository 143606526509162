import React, { ReactNode, useEffect, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useDispatch, useSelector } from "react-redux";

import {
  getCropsTelematics,
  getIsFetchingCropsTelematics,
} from "../../../../shared/api/telematics/drives/drives.selectors";
import { selectDateRange } from "../../../selectors/telematicsTabs.selectors";

import { fetchCropsTelematics } from "../../../actions/telematicsList.actions";

import * as sortOrder from "../../../../shared/constants/sortOrder.constants";

import { resetCropsTelematicsApi } from "../../../../shared/api/telematics/drives/drives.api";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import {
  CropSortColumn,
  CropTo,
} from "../../../../shared/api/telematics/telematics.types";

interface Props {
  defaultValues?: CropTo[];
  externalClasses?: Record<string, string>;
  label: ReactNode;
  onChange: (items: CropTo[]) => void;
}

const defaultDefaultValues: CropTo[] = [];

const handleGetSelected = (option: CropTo, value?: CropTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const CropsSelector = ({
  defaultValues = defaultDefaultValues,
  externalClasses = {},
  label,
  onChange,
}: Props) => {
  const classes = useAdvancedFilterStyles();
  const dispatch = useDispatch();

  const suggestions = useSelector(getCropsTelematics);
  const isFetching = useSelector(getIsFetchingCropsTelematics);
  const dateFrom = useSelector(selectDateRange).dateFrom;
  const dateTo = useSelector(selectDateRange).dateTo;

  useEffect(() => {
    dispatch(
      fetchCropsTelematics(
        dateFrom,
        dateTo,
        CropSortColumn.NAME,
        sortOrder.ASC,
      ),
    );
    return () => {
      dispatch(resetCropsTelematicsApi());
    };
  }, [dateFrom, dateTo, dispatch]);

  const handleRenderOption = useCallback(
    (option: CropTo) => (
      <div>
        <div>{option.name ?? option.code}</div>
        <div className={classes.subtext}>{option.code}</div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: CropTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? value.code}
            label={
              <>
                {value.name ?? value.code}
                <span className={classes.subtext}>{value.code}</span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="crop-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={suggestions}
      testId="crop-filter"
    />
  );
};

export default CropsSelector;
