import React, { Component } from "react";

import { ConnectedRouter } from "connected-react-router";
import createHistory from "history/createBrowserHistory";
import PropTypes from "prop-types";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";

import { LANG_KEYS } from "../../lang/lang.constants";

import createReduxStore from "../../../reducers/store";
import { CfConfigProvider } from "../../hocs/context/withConfig";
import { CfFarmProvider } from "../../hocs/context/withFarm";
import commonTranslationsCZ from "../../lang/locale-cs-CZ.json";
import commonTranslationsEL from "../../lang/locale-el-GR.json";
import commonTranslationsEN from "../../lang/locale-en-US.json";
import commonTranslationsES from "../../lang/locale-es-ES.json";
import commonTranslationsHU from "../../lang/locale-hu-HU.json";
import commonTranslationsPL from "../../lang/locale-pl-PL.json";
import commonTranslationsRO from "../../lang/locale-ro-RO.json";
import commonTranslationsRU from "../../lang/locale-ru-UA.json";
import commonTranslationsSR from "../../lang/locale-sr-LATN-RS.json";
import Localization from "../../services/Localization.service";
import LocalStorage from "../../services/LocalStorage.service";
import UiCache from "../../services/UiCache.service";
import WithToggles from "../../toggles/WithToggles";
import WithReduxdevtools from "../../with-reduxdevtools";

window.resetCache = () => {
  UiCache.deleteParams();
  setTimeout(() => location.reload(), 1000);
};

function saveToCache(state, cacheKey) {
  const { ui } = state;
  if (ui) {
    const uiToCache = {};
    const keysToInclude = ui?.cache;
    if (!keysToInclude) return;

    keysToInclude.forEach((key) => {
      uiToCache[key] = { ...ui[key] };
    });
    UiCache.storeParams(cacheKey, uiToCache);
  }
}

/*
 * For now `farm` prop is required, however it is not being always used by childs
 * (e.g. reset & signup), thus for now, it is mocked. However in future this wrapper
 * should be splitted into wrapper for out-of-the-app views (reset, signup, login) &
 * in-the-app views (the rest...)
 * */
export default class CfApp extends Component {
  constructor(props) {
    super(props);

    const {
      apiProps,
      cacheKey,
      config,
      farm: { id: farmId },
      reducer,
    } = props;

    UiCache.initialize(farmId.toString());
    this.isCached = Boolean(cacheKey);

    // cache (in)validation
    if (this.isCached && farmId) {
      const cachedState = UiCache.retrieveParams(cacheKey);
      if (cachedState) {
        const UI_CACHE_VERSION =
          LocalStorage.loadFromLocalStorage("UI_CACHE_VERSION");
        const isCacheValid =
          UI_CACHE_VERSION === process.env.BUILD_INFO_APP_COMMIT;
        if (isCacheValid) {
          this.cache = { ui: { ...cachedState } };
        } else {
          UiCache.deleteParams();
          this.cache = null;
        }
      }
      LocalStorage.saveToLocalStorage(
        process.env.BUILD_INFO_APP_COMMIT,
        "UI_CACHE_VERSION",
      );
    }

    const history = createHistory();
    const store = createReduxStore(
      history,
      apiProps,
      reducer,
      this.isCached && this.cache ? this.cache : null,
      {
        collapsed: (getState, action, logEntry) => !logEntry.error,
      },
      config.environment,
    );

    this.state = {
      store,
      history,
    };
  }

  componentWillUnmount() {
    if (this.isCached) {
      saveToCache(this.state.store.getState(), this.props.cacheKey);
    }
  }

  commonTranslations = {
    [LANG_KEYS.CZ]: commonTranslationsCZ,
    [LANG_KEYS.EN]: commonTranslationsEN,
    [LANG_KEYS.SR]: commonTranslationsSR,
    [LANG_KEYS.ES]: commonTranslationsES,
    [LANG_KEYS.RU]: commonTranslationsRU,
    [LANG_KEYS.EL]: commonTranslationsEL,
    [LANG_KEYS.RO]: commonTranslationsRO,
    [LANG_KEYS.HU]: commonTranslationsHU,
    [LANG_KEYS.PL]: commonTranslationsPL,
  };

  render() {
    const { history, store } = this.state;
    const { apiProps, config, farm, reducer, render, ...rest } = this.props;

    return (
      <ReduxProvider store={store}>
        <WithReduxdevtools environment={config.environment}>
          <WithToggles>
            <CfConfigProvider config={config}>
              <CfFarmProvider farm={farm}>
                <ConnectedRouter history={history}>
                  <CookiesProvider defaultSetOptions={{ path: "/" }}>
                    {render({
                      history,
                      config,
                      farm,
                      langId: Localization.getLang(),
                      ...rest,
                    })}
                  </CookiesProvider>
                </ConnectedRouter>
              </CfFarmProvider>
            </CfConfigProvider>
          </WithToggles>
        </WithReduxdevtools>
      </ReduxProvider>
    );
  }
}

CfApp.propTypes = {
  render: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  reducer: PropTypes.func.isRequired,
  apiProps: PropTypes.object.isRequired,
  // in some places, `farm` prop is not needed, see comment above
  farm: PropTypes.object.isRequired,
  // weather the tree's redux state is cached
  cacheKey: PropTypes.string,
};

CfApp.defaultProps = {
  farm: {
    boundingBox: {},
    code: "",
    customer: {},
    id: "",
    name: "",
  },
  cacheKey: null,
};
