import React, { CSSProperties, ReactNode, useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { TableCellProps } from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcels,
  getIsFetchingParcelsStatistics,
  getParcelListFocusedRow,
  getParcels,
  getTotalCount,
} from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getParcelListPage,
  getParcelListOrder,
  getParcelListOrderBy,
  getParcelListRowsPerPage,
  getParcelListSelected,
  getParcelListSelectedOnPage,
  getParcelListTextFilter,
} from "../../selectors/parcelList.selectors";

import { setFocusedRow } from "../../../shared/actions/parcels.actions";
import { fetchParcels } from "../../actions/parcelList.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelList.reducer";

import {
  resetParcelsApi,
  resetParcelApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { COLOR_GREY } from "../../../../../theme";
import { Thunk } from "../../../../../types";

import ParcelTableRow from "./ParcelTableRow";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import { ParcelListAdvancedFilter } from "../../../../../shared/api/agroevidence/parcels/parcels.types";

const getColDesc = (
  sortable: boolean,
  label: ReactNode,
  style?: CSSProperties,
  rightPadding?: boolean,
) => ({
  align: "inherit" as TableCellProps["align"],
  sortable,
  label,
  style,
  rightPadding,
});

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  localName: getColDesc(
    true,
    <span>
      <FormattedMessage id="common.name" />
    </span>,
  ),
  blockNumber: getColDesc(true, <FormattedMessage id="common.short-code" />),
  area: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.area" /> (ha)
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.area-dpb" /> (ha)
      </p>
    </span>,
    { textAlign: "right" },
    true,
  ),
  culture: getColDesc(
    false,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.culture" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.crop" />
      </p>
    </span>,
  ),
  nitrateVulnerable: getColDesc(false, <FormattedMessage id="term.NSA" />),
  validFrom: getColDesc(true, <FormattedMessage id="common.validFrom" />),
  zone: getColDesc(true, <FormattedMessage id="common.zone" />),
  center: getColDesc(true, <FormattedMessage id="common.center" />),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  advancedFilter: ParcelListAdvancedFilter;
  farmId: string;
  focusedRow?: string;
  onAssignCenter: (
    centerId: string,
    parcelIds: string[],
    bulk: boolean,
  ) => void;
  shouldReloadData: boolean;
  textFilter?: string;
};
export type Props = ReduxProps & OwnProps;

export const ParcelTable = ({
  advancedFilter,
  count,
  farmId,
  fetchParcels,
  focusedRow,
  isFetchingParcels,
  isFetchingStatistics,
  onAssignCenter,
  order,
  orderBy,
  page,
  parcels,
  resetParcelApi,
  resetParcelsApi,
  rowsPerPage,
  selected,
  selectedOnPage,
  setFocusedRow,
  shouldReloadData,
  textFilter = "",
}: Props) => {
  useEffect(
    () => () => {
      resetParcelsApi();
      resetParcelApi();
      setFocusedRow(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchParcels();
    // eslint-disable-next-line max-len
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    textFilter,
    advancedFilter,
    shouldReloadData,
    fetchParcels,
  ]);

  return (
    <CfTableWrapper testId="parcel-list">
      <CfTableHead
        columns={columns}
        items={parcels}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetchingParcels && <CfTableBodyLoader columns={columns} />}

      {parcels.length && !isFetchingParcels ? (
        <TableBody>
          {parcels.map((parcel) => (
            <ParcelTableRow
              farmId={farmId}
              focusedRow={focusedRow}
              isInit={!isFetchingParcels && !isFetchingStatistics && count > 0}
              key={parcel.id}
              onAssignCenter={onAssignCenter}
              parcel={parcel}
              selected={selected}
            />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  parcels: getParcels()(
    state,
  ) as ParcelsState["api"]["agroevidence"]["parcels"]["items"],
  isFetchingParcels: getIsFetchingParcels()(state),
  isFetchingStatistics: getIsFetchingParcelsStatistics(state),
  count: getTotalCount(state),
  page: getParcelListPage(state),
  order: getParcelListOrder(state),
  orderBy: getParcelListOrderBy(state),
  rowsPerPage: getParcelListRowsPerPage(state),
  selected: getParcelListSelected(state),
  selectedOnPage: getParcelListSelectedOnPage(state),
  textFilter: getParcelListTextFilter(state),
  focusedRow: getParcelListFocusedRow(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchParcels,
      resetParcelsApi,
      resetParcelApi,
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ParcelTable);
