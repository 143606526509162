import React, { FC, useState, useEffect, useMemo, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getAggregatedDriveKeys,
  getApiError,
  isAggregatedDetailError,
} from "../../../shared/api/telematics/aggregations/aggregations.selectors";

import {
  fetchRelatedDrives,
  fetchRelatedDrivesByMachine,
} from "../../actions/telematicsAggregationDetail.actions";

import { TELEMATICS_URLS } from "../../telematics.constants";

import {
  changeDrivesStateApi,
  deleteAggregatedDriveApi,
  editAggregatedDriveApi,
  editAggregatedDriveKeysApi,
  getAggregatedDriveKeysApi,
  getDriverAggregationDetailApi,
  separateDriveApi,
  separateIntervalApi,
} from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import ShowHideMap from "../../../shared/components/common/ShowHideMap/ShowHideMap";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useWidth from "../../../shared/hooks/useWidth";
import { AsyncFn, RsaaApiError, Thunk } from "../../../types";
import DetailContentForm from "../../components/TelematicsAggregationDetailContent/DetailContentForm";
import DriveKeysForm from "../../components/TelematicsAggregationDetailContent/DriveKeysForm";
import EditBox from "../../components/TelematicsAggregationDetailContent/EditBox";
import HeaderWithRelatedRides from "../../components/TelematicsAggregationDetailContent/HeaderWithRelatedRides";
import TimelineContainer from "../../components/TelematicsAggregationDetailContent/TimelineContainer";
import TelematicsDetailMap from "../../components/TelematicsDetailMap/TelematicsDetailMap";

import { useTelematicsAggregationDetailStyles } from "./styles";
import { BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH } from "./TelematicsAggregationDetail";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  DriveDetailTo,
  DriveUpdateTo,
  State,
  DriveValidationType,
  Catalogue,
  Source,
  Type,
  WinfasTo,
  WinfasUpdateTo,
  DriveSeparationTimeTo,
  DriveSeparationIntervalsTo,
  Affiliation,
} from "../../../shared/api/telematics/telematics.types";

interface Props {
  apiError?: RsaaApiError;
  catalogueType: string;
  changeState: (ids: string[], state: State) => void;
  deleteDrive: (id: number) => void;
  driveDetail?: DriveDetailTo;
  driveId: string;
  driveKeys: WinfasTo | null;
  editDrive: (id: number, drive: DriveUpdateTo) => void;
  editKeys: (id: string, value: WinfasUpdateTo) => void;
  farmId: string;
  fetchDrive: (driveId: string) => void;
  fetchDriveKeys: (id: string) => void;
  fetchRelatedDrives: (
    driverCode: string,
    dateFrom: string,
    dateTo: string,
  ) => void;
  fetchRelatedDrivesByMachine: (
    machine: string,
    dateFrom: string,
    dateTo: string,
  ) => void;
  handleGoBack: () => void;
  isAggregatedDetailError: boolean;
  isFetching: boolean;
  ngGoToAction: (actionId?: string) => void;
  previousPage: keyof typeof TELEMATICS_URLS | undefined;
  separateDriveApi: (id: number, payload: DriveSeparationTimeTo) => void;
  separateIntervalApi: (
    id: number,
    payload: DriveSeparationIntervalsTo,
  ) => void;
}

const TelematicsAggregationDetailLayout: FC<Props> = ({
  apiError,
  catalogueType,
  changeState,
  deleteDrive,
  driveDetail,
  driveId,
  driveKeys,
  editDrive,
  editKeys,
  farmId,
  fetchDrive,
  fetchDriveKeys,
  fetchRelatedDrives,
  fetchRelatedDrivesByMachine,
  handleGoBack,
  isAggregatedDetailError,
  isFetching,
  ngGoToAction,
  previousPage,
  separateDriveApi,
  separateIntervalApi,
}) => {
  const history = useHistory();
  const classes = useTelematicsAggregationDetailStyles();
  const width = useWidth();
  const showSnackbar = useContext(SnackbarContext);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isPostponed, setIsPostponed] = useState<boolean>(false);
  const [displayMap, setDisplayMap] = useState(true);
  const [isEditingTimeline, setIsEditingTimeline] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [isEditingKeys, setIsEditingKeys] = useState(false);
  const [parcelId, setParcelId] = useState<string | undefined>();
  const notCompanyMachine =
    driveDetail?.machine?.affiliation !== Affiliation.COMPANY;
  const editingMode = () =>
    isEditingTimeline || isEditingContent || isEditingKeys;
  const isDesktop = width !== "xs" && width !== "sm";

  const approvalValidationErrors = useMemo(() => {
    const errors = driveDetail?.validation?.validationErrors || [];
    return {
      driverCode: errors.find((e) => e === DriveValidationType.DRIVER_EXISTS),
      cultivated: errors.find(
        (e) => e === DriveValidationType.CULTIVATED_NOT_ZERO,
      ),
      distance: errors.find((e) => e === DriveValidationType.DISTANCE_NOT_ZERO),
      parcel:
        errors.find((e) => e === DriveValidationType.PARCEL_EXISTS) ||
        errors.find(
          (e) =>
            e === DriveValidationType.CROP_EXISTS ||
            e === DriveValidationType.CENTER_EXISTS,
        ),
      productionOperation: errors.find(
        (e) => e === DriveValidationType.PRODUCTION_OPERATION_EXISTS,
      ),
      supplierKey: errors.find(
        (e) =>
          e === DriveValidationType.SUPPLIER_KEY_EXISTS ||
          e === DriveValidationType.SUPPLIER_KEY_VALID,
      ),
      customerKey: errors.find(
        (e) =>
          e === DriveValidationType.CUSTOMER_KEY_EXISTS ||
          e === DriveValidationType.CUSTOMER_KEY_VALID,
      ),
      duration: errors.find(
        (e) =>
          e === DriveValidationType.DURATION_NOT_ZERO ||
          e === DriveValidationType.TIMES_DONT_OVERLAP,
      ),
      client: errors.find((e) => e === DriveValidationType.CLIENT_EXISTS),
      externalMachine: errors.find(
        (e) => e === DriveValidationType.MACHINE_AFFILIATION_COMPANY,
      ),
    };
  }, [driveDetail?.validation?.validationErrors]);

  const aggregatedGeometry = useMemo(() => {
    // to avoid blank map
    if (!driveDetail?.drivePart) return undefined;
    if (driveDetail.source === Source.MANUAL) return undefined;

    const data = {
      type: "MultiLineString",
      coordinates: [] as number[][],
    };
    driveDetail?.drivePart.forEach((part) => {
      if (part.geometry?.coordinates) {
        data.coordinates = [
          ...data.coordinates,
          part.geometry.coordinates as unknown as number[],
        ];
      }
    });
    return data;
  }, [driveDetail]);

  useEffect(() => {
    if (driveId) {
      setIsEditingTimeline(false);
    }
  }, [driveId, fetchDrive, fetchDriveKeys]);

  useEffect(() => {
    if (!driveDetail) return;

    if (driveDetail.state === State.DEFERRED) {
      setIsPostponed(true);
    } else {
      setIsPostponed(false);
    }

    setIsApproved(
      driveDetail.state === State.APPROVED ||
        driveDetail.state === State.THIRD_PARTY_ACKNOWLEDGED,
    );

    setParcelId(driveDetail?.parcelId);
  }, [driveDetail]);

  const isManualDrive = driveDetail?.type === Type.MANUAL;
  const showIntervalsBar =
    driveDetail?.type !== Type.MANUAL ||
    (!isEditingTimeline && driveDetail?.type === Type.MANUAL);

  const handleToggleMap = () => setDisplayMap((prevState) => !prevState);
  const handleApproveClick = () => {
    const newState = isApproved ? State.NOT_APPROVED : State.APPROVED;
    (changeState as AsyncFn<string[], State>)([driveId], newState).then(
      (res) => {
        if (!res.error) {
          setIsApproved((prevState) => !prevState);
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
        }
      },
    );
  };

  const handlePostponedClick = () => {
    const newState = isPostponed ? State.NOT_APPROVED : State.DEFERRED;
    (changeState as AsyncFn<string[], State>)([driveId], newState).then(
      (res) => {
        if (!res.error) {
          setIsPostponed((prevState) => !prevState);
        }
      },
    );
  };

  const handleResetContentForm = () => {
    setParcelId(driveDetail?.parcelId);
    setIsEditingContent(false);
  };

  const handleResetTimelineForm = () => {
    setParcelId(driveDetail?.parcelId);
    setIsEditingTimeline(false);
  };

  const handleResetKeysForm = () => {
    setIsEditingKeys(false);
  };

  const handleSaveContentForm = (values: DriveUpdateTo) => {
    if (!driveDetail) return;
    (editDrive as AsyncFn<number, DriveUpdateTo>)(driveDetail.id, values).then(
      (res) => {
        if (!res.error) {
          setIsEditingContent(false);
          fetchDrive(driveId);
          fetchDriveKeys(driveId);
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
        }
      },
    );
  };

  const handleSaveTimeSplitting = (values: DriveSeparationTimeTo) => {
    if (!driveDetail) return;
    (separateDriveApi as AsyncFn<number, DriveSeparationTimeTo>)(
      driveDetail.id,
      { time: values.time },
    )
      .then((res) => {
        if (!res.error) {
          const { dateFrom: df, driver, machineName } = driveDetail;
          const dateFrom = moment(df).startOf("day").toISOString();
          const dateTo = moment(df).endOf("day").toISOString();
          const driverCode = driver.code;

          if (previousPage === "machines") {
            fetchRelatedDrivesByMachine(
              machineName ?? "nomachine",
              dateFrom,
              dateTo,
            );
          } else {
            fetchRelatedDrives(driverCode ?? "nodriver", dateFrom, dateTo);
          }

          fetchDrive(driveId);
          fetchDriveKeys(driveId);
          handleResetTimelineForm();
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
        }
      })
      .finally(() => setIsEditingTimeline(false));
  };

  const handleSaveCollisionsForm = (values: DriveSeparationIntervalsTo) =>
    new Promise<void>((resolve, reject) => {
      if (!driveDetail) {
        reject(new Error());
        return;
      }

      (separateIntervalApi as AsyncFn<number, DriveSeparationIntervalsTo>)(
        driveDetail.id,
        values,
      )
        .then((res) => {
          if (!res.error) {
            const { dateFrom: df, driver, machine } = driveDetail;
            const dateFrom = moment(df).startOf("day").toISOString();
            const dateTo = moment(df).endOf("day").toISOString();
            const driverCode = driver.code;

            if (previousPage === "machines") {
              fetchRelatedDrivesByMachine(
                machine?.name ?? "nomachine",
                dateFrom,
                dateTo,
              );
            } else {
              fetchRelatedDrives(driverCode ?? "nodriver", dateFrom, dateTo);
            }

            fetchDrive(driveId);
            fetchDriveKeys(driveId);
            handleResetTimelineForm();
            resolve();
          } else {
            if (
              res.payload?.response?.code !==
              BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH
            ) {
              showSnackbar({
                message: (
                  <FormattedMessage id="TelematicsDetail.editRideError" />
                ),
                isError: true,
              });
            }
            reject(res.payload?.response?.code);
          }
        })
        .catch((error) => {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
          reject(error);
        });
    });

  const handleSaveKeysForm = (values: WinfasUpdateTo) => {
    (editKeys as AsyncFn<string, WinfasUpdateTo>)(driveId, values).then(
      (res) => {
        if (!res.error) {
          setIsEditingKeys(false);
          fetchDrive(driveId);
          fetchDriveKeys(driveId);
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
        }
      },
    );
  };

  const handleDeleteDrive = () => {
    if (!driveDetail?.id) return;
    (deleteDrive as AsyncFn<number>)(driveDetail.id).then((res) => {
      if (!res.error) {
        history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}`);
      } else {
        showSnackbar({
          message: <FormattedMessage id="TelematicsDetail.deleteRideError" />,
          isError: true,
        });
      }
    });
  };

  if (!driveDetail && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <CfErrorPage
      error={isAggregatedDetailError ? apiError : undefined}
      error40xHeadingTranslId="error.heading.telematicsDetail"
      error40xMessageTranslId="error.message.telematicsDetail"
      handle400s
    >
      {driveDetail && (
        <Grid className={classes.wrapper} container>
          <Grid
            className={classes.bodyWrapper}
            item
            md={displayMap ? 6 : 12}
            xs={12}
          >
            <Scrollbars>
              <div className={classes.body}>
                <HeaderWithRelatedRides
                  driveDetail={driveDetail}
                  handleApproveClick={handleApproveClick}
                  handleGoBack={handleGoBack}
                  handlePostponedClick={handlePostponedClick}
                  isApproved={isApproved}
                  isEditing={editingMode()}
                  isPostponed={isPostponed}
                  ngGoToAction={ngGoToAction}
                  handleDeleteDrive={
                    isManualDrive ? handleDeleteDrive : undefined
                  }
                />
                <EditBox
                  disabled={editingMode() || notCompanyMachine}
                  handleStartEditing={() => setIsEditingContent(true)}
                  headingId="TelematicsAggregations.detail.section.operation"
                  isApproved={isApproved}
                  isEditing={isEditingContent}
                  visible={!!driveDetail}
                >
                  <DetailContentForm
                    approvalValidationErrors={approvalValidationErrors}
                    driveDetail={driveDetail}
                    handleReset={handleResetContentForm}
                    handleSave={handleSaveContentForm}
                    isEditing={isApproved ? false : isEditingContent}
                    parcelId={parcelId}
                    refreshKey={`${displayMap}`}
                    setParcelId={setParcelId}
                  />
                </EditBox>
                {showIntervalsBar && (
                  <EditBox
                    handleStartEditing={() => setIsEditingTimeline(true)}
                    headingId="TelematicsAggregations.detail.section.timeline"
                    isApproved={isApproved}
                    isEditing={isEditingTimeline}
                    showEditButton={!isManualDrive}
                    visible={!!driveDetail}
                    disabled={
                      editingMode() || isManualDrive || notCompanyMachine
                    }
                  >
                    <TimelineContainer
                      approvalValidationErrors={approvalValidationErrors}
                      driveDetail={driveDetail}
                      handleReset={handleResetTimelineForm}
                      handleSaveCollisions={handleSaveCollisionsForm}
                      handleSaveTimeSplitting={handleSaveTimeSplitting}
                      isEditing={isApproved ? false : isEditingTimeline}
                      refreshKey={`${displayMap}`}
                      showIntervalsBar={showIntervalsBar}
                    />
                  </EditBox>
                )}
                <EditBox
                  disabled={editingMode() || notCompanyMachine}
                  handleStartEditing={() => setIsEditingKeys(true)}
                  headingId="TelematicsAggregations.detail.section.keys"
                  isApproved={isApproved}
                  isEditing={isEditingKeys}
                  visible={catalogueType === Catalogue.WINFAS}
                >
                  <DriveKeysForm
                    approvalValidationErrors={approvalValidationErrors}
                    date={driveDetail.dateFrom}
                    driveKeys={driveKeys}
                    handleReset={handleResetKeysForm}
                    handleSave={handleSaveKeysForm}
                    isEditing={isEditingKeys}
                  />
                </EditBox>
              </div>
            </Scrollbars>
            <ShowHideMap
              handleClick={handleToggleMap}
              isMapHidden={!displayMap}
            />
          </Grid>
          <Grid
            className={classes.mapWrapper}
            item
            md={6}
            style={{ display: displayMap ? "block" : "none" }}
            xs={12}
          >
            {!isDesktop && (
              <ShowHideMap handleClick={handleToggleMap} isMapHidden={false} />
            )}
            <TelematicsDetailMap
              geometry={aggregatedGeometry}
              geometryPerDay={driveDetail?.geometryPerDay}
              operation={driveDetail?.operation}
              parcelId={parcelId}
            />
          </Grid>
        </Grid>
      )}
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  apiError: getApiError(state),
  driveKeys: getAggregatedDriveKeys(state),
  isAggregatedDetailError: isAggregatedDetailError(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrive: getDriverAggregationDetailApi,
      changeState: changeDrivesStateApi,
      editDrive: editAggregatedDriveApi,
      editKeys: editAggregatedDriveKeysApi,
      fetchDriveKeys: getAggregatedDriveKeysApi,
      deleteDrive: deleteAggregatedDriveApi,
      separateDriveApi,
      fetchRelatedDrives,
      fetchRelatedDrivesByMachine,
      separateIntervalApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsAggregationDetailLayout);
