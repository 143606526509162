import React from "react";

import { Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel, {
  TableSortLabelProps,
} from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import { useDispatch } from "react-redux";

import { setTableSorting, setTableSelected } from "../../actions/table.actions";

import * as sortOrder from "../../constants/sortOrder.constants";

type Props = React.PropsWithChildren<{
  bulkMode?: boolean;
  checked?: boolean;
  className?: string;
  columns: Record<
    string,
    {
      align?: TableCellProps["align"];
      hideOnMobile?: boolean;
      label?: (() => React.ReactNode) | React.ReactNode;
      rightPadding?: boolean;
      sortable?: boolean;
      style?: React.CSSProperties;
      superRightPadding?: boolean;
    }
  >;
  customSelectHandler?: (checked: boolean) => void;
  customSortHandler?: (
    colId: string,
  ) => React.MouseEventHandler<HTMLSpanElement>;
  disabledCheckbox?: boolean;
  indeterminate?: boolean;
  itemId?: string;
  items?: unknown[];
  namespace?: string;
  order?: TableSortLabelProps["direction"];
  orderBy?: string;
  selected?: unknown[];
  selectedOnPage?: unknown[];
}>;

const CfTableHead = ({
  bulkMode = true,
  checked,
  children,
  className,
  columns,
  customSelectHandler,
  customSortHandler,
  disabledCheckbox,
  indeterminate,
  itemId = "id",
  items = [],
  namespace,
  order,
  orderBy,
  selected,
  selectedOnPage = [],
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCheckboxEl = () => {
    const itemsLength = items ? items.length : null;
    const selectedItemsLength = selectedOnPage?.length ?? 0;

    const anyItemsChecked =
      selectedItemsLength > 0 &&
      itemsLength !== null &&
      selectedItemsLength < itemsLength;

    return (
      <TableCell
        padding="checkbox"
        style={{ width: "50px" }}
        classes={{
          root: classes.root,
        }}
      >
        <Checkbox
          color="primary"
          data-test="table-checkbox"
          disabled={disabledCheckbox}
          indeterminate={indeterminate ?? anyItemsChecked}
          checked={
            checked !== undefined
              ? checked
              : selectedItemsLength > 0 && selectedItemsLength === itemsLength
          }
          onChange={(e, checked) => {
            if (customSelectHandler) {
              customSelectHandler(
                anyItemsChecked || indeterminate ? !checked : checked,
              );
            } else {
              handleSelectAll(
                e,
                anyItemsChecked || indeterminate ? !checked : checked,
              );
            }
          }}
        />
      </TableCell>
    );
  };

  const handleSelectAll = (
    _evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const selectedVar = selected ?? [];
    if (checked) {
      dispatch(
        setTableSelected(
          // @ts-expect-error item is of type unknown
          uniq([...selectedVar, ...items.map((item) => item[itemId])]),
          namespace,
        ),
      );
    } else {
      dispatch(
        setTableSelected(difference(selectedVar, selectedOnPage), namespace),
      );
    }
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (_evt: React.MouseEvent, colId: string) => {
    const newOrder =
      colId !== orderBy || order === sortOrder.DESC
        ? sortOrder.ASC
        : sortOrder.DESC;
    dispatch(setTableSorting(newOrder, colId, namespace ?? null));
  };

  return (
    <TableHead>
      {children}
      <TableRow className={className}>
        {Array.isArray(selected) && bulkMode && getCheckboxEl()}
        {Object.entries(columns).map(([colId, col]) => (
          <TableCell
            align={col.align}
            key={colId}
            size="small"
            style={col.style}
            classes={{
              root: classnames({
                [classes.rightPadding]: col.rightPadding,
                [classes.superRightPadding]: col.superRightPadding,
                [classes.desktopOnly]: col.hideOnMobile,
                [classes.root]: true,
              }),
            }}
          >
            {col.sortable ? (
              <TableSortLabel
                active={orderBy === colId}
                data-test={`order-${order}`}
                direction={orderBy === colId ? order : sortOrder.ASC}
                onClick={
                  customSortHandler
                    ? customSortHandler(colId)
                    : createSortHandler(colId)
                }
              >
                {typeof col.label === "function" ? col.label() : col.label}
              </TableSortLabel>
            ) : (
              <span>
                {typeof col.label === "function" ? col.label() : col.label}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[500],
  },
  rightPadding: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
    },
  },
  superRightPadding: {
    [theme.breakpoints.up("xl")]: {
      paddingRight: 200,
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default CfTableHead;
