import React, { useEffect, useState, useCallback, useRef } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";

import { setTextFilter } from "../../actions/filter.actions";

import { useTypedIntl } from "../../hooks/useTypedIntl";

type Props = {
  translId?: string;
  initialValue?: string;
  name?: string;
  onFilterReset?: () => void;
  onFilterChange?: (e: unknown) => void;
  namespace?: string;
  disableUnderline?: boolean;
  autoFocus?: boolean;
  customStyles?: React.CSSProperties;
};

const CfTextFilter = ({
  autoFocus = false,
  customStyles = undefined,
  disableUnderline = false,
  initialValue = "",
  name = "text-filter",
  namespace,
  onFilterChange,
  onFilterReset,
  translId,
}: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useTypedIntl();
  const styles = useStyles();
  const [value, setValue] = useState(initialValue);

  const prevInitialValue = useRef(initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFnc = useCallback(
    debounce((inputValue) => {
      if (onFilterChange) {
        onFilterChange(inputValue);
      } else {
        dispatch(setTextFilter(inputValue, namespace));
      }
    }, 500),
    [onFilterChange, namespace, dispatch],
  );

  useEffect(() => {
    if (initialValue !== prevInitialValue.current && initialValue !== value) {
      setValue(initialValue);
    }
    prevInitialValue.current = initialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(
    () => () => {
      debouncedFnc.cancel();
    },
    [debouncedFnc],
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedFnc(e.target.value);
  };

  const onResetBtnClick = () => {
    if (value.length) {
      setValue("");
      if (onFilterReset) {
        return onFilterReset();
      }
      return dispatch(setTextFilter("", namespace));
    }
  };

  const icon = value.length ? <ClearIcon /> : <SearchIcon />;
  const translationId = translId
    ? formatMessage({
        id: translId,
      })
    : undefined;

  return (
    <div className={styles.wrapper}>
      <Input
        autoFocus={autoFocus}
        disableUnderline={disableUnderline}
        name={name}
        onChange={onInputChange}
        placeholder={translationId}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Text filter"
              color="default"
              onClick={onResetBtnClick}
              size="large"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        }
        style={
          customStyles || {
            width: "100%",
          }
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    fontWeight: 400,
  },
});

export default CfTextFilter;
