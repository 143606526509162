import React, { useEffect, useRef, useState } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";

import {
  getErrorRules,
  getIsFetchingRules,
} from "../../../shared/api/automation/rules/rules.selectors";

import { fetchUnfilteredRules } from "../../actions/notificationList.actions";

import * as services from "../../../shared/constants/services.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import ServiceNotPurchased from "../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import { links } from "../../../shared/constants/links";
import { NotificationsIcon } from "../../../shared/icons/navbar/NotificationsIcon";
import NotificationDetail from "../NotificationDetail/NotificationDetail";
import NotificationList from "../NotificationList/NotificationList";

type Props = {
  langId: LANGUAGE_ID;
  farmId: string;
};

const Notifications = ({ farmId, langId }: Props) => {
  const [activated, setActivated] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const isFetching = useSelector(getIsFetchingRules);
  const error = useSelector(getErrorRules);

  const prevIsFetching = useRef(isFetching);

  useEffect(() => {
    dispatch(fetchUnfilteredRules());
  }, [dispatch]);

  useEffect(() => {
    if (
      prevIsFetching.current &&
      !isFetching &&
      !(error.isError && error.code === 403)
    ) {
      setActivated(true);
    }

    prevIsFetching.current = isFetching;
  }, [isFetching, error]);

  return (
    <div className={classes.wrapperStyle}>
      {isFetching && !activated ? (
        <CfLoader />
      ) : (
        <>
          {activated ? (
            <Switch>
              <Route
                exact
                path="/farm/:farmId/notifications"
                render={(routerProps) => (
                  <NotificationList
                    farmId={farmId}
                    history={routerProps?.history}
                    langId={langId}
                  />
                )}
              />
              <Route
                exact
                path="/farm/:farmId/notifications/new"
                render={(routerProps) => (
                  <NotificationDetail
                    farmId={farmId}
                    history={routerProps.history}
                  />
                )}
              />
              <Route
                path="/farm/:farmId/notifications/:notificationId"
                render={(routerProps) => (
                  <NotificationDetail
                    farmId={farmId}
                    {...routerProps}
                    isExisting={true}
                  />
                )}
              />
            </Switch>
          ) : (
            <div className={classes.noNotifications}>
              <PageHeader
                classes={{ header: classes.header }}
                heading={<PageHeading translationId="common.notifications" />}
              />
              <CfStatusPanel
                icon={NotificationsIcon}
                linkText={<FormattedMessage id="common.findOutMore" />}
                testId="no-notifications"
                titleWithIcon={true}
                customContent={
                  <ServiceNotPurchased serviceId={services.NOTIFICATIONS} />
                }
                linkHref={
                  langId === "cs-CZ" ? links.cs.homepage : links.en.homepage
                }
                title={
                  <FormattedMessage id="Notifications.notPurchased.title" />
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapperStyle: {
    height: "100%",
  },
  noNotifications: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export default Notifications;
