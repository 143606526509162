import React, { useContext, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getApplicationsSortedPaginated,
  getApplicationsTotalCount,
} from "../../../../selectors/plantProtectionApplications.selectors";
import {
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../../../selectors/sectionList.selectors";

import {
  deletePlantProtectionApplication,
  fetchPlantProtectionApplications,
} from "../../../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../../../reducers/namespace.reducer";

import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../../../shared/containers/CfTextFilter/CfTextFilter";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { getColDesc } from "../../../../../shared/misc/helper";
import { APPLICATIONS_COLUMN_NAMES } from "../../plantProtection.columns";

import { ApplicationEditDialog } from "./ApplicationEditDialog";
import { ApplicationRow } from "./ApplicationRow";

import { CATALOGUES_NAMESPACES } from "../../../../../reducers/catalogues.reducer.types";
import { PlantProtectionDetailRegistrationApplicationTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  canUpdate?: boolean;
  isEditing: boolean;
};

export const ApplicationsTable = ({ canUpdate = false, isEditing }: Props) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const applications = useSelector(getApplicationsSortedPaginated);
  const namespace = useSelector(getSelectedNamespace);
  const search = useSelector(getSectionListSearch);
  const order = useSelector(getSectionListOrder);
  const orderBy = useSelector(getSectionListOrderBy);
  const page = useSelector(getSectionListPage);
  const rowsPerPage = useSelector(getSectionListRowsPerPage);
  const count = useSelector(getApplicationsTotalCount);

  const { plantProtectionId } = useParams<{ plantProtectionId: string }>();

  const {
    on: showEditDialog,
    setOff: handleEditDialogClose,
    setOn: handleEditDialogOpen,
  } = useToggle();

  useEffect(() => {
    dispatch(
      setSelectedNamespace(CATALOGUES_NAMESPACES.PLANT_PROTECTION_APPLICATIONS),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => handleEditDialogOpen();

  const onDeleteAccept = (applicationId: string) => {
    (
      dispatch(
        deletePlantProtectionApplication(plantProtectionId, applicationId),
      ) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.applications.delete.error" />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: (
          <FormattedMessage id="Catalogues.plantProtection.applications.delete.success" />
        ),
        isSuccess: true,
      });
      dispatch(fetchPlantProtectionApplications(plantProtectionId));
    });
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="plantProtection-applications-list-text-filter"
              namespace={namespace}
              translId="Catalogues.plantProtection.applications.search"
            />
          </div>
        </div>
        {canUpdate && (
          <Button
            className={classes.addButton}
            color="secondary"
            disabled={isEditing}
            id="add-application"
            onClick={handleCreate}
            size="small"
            variant="contained"
          >
            <AddIcon sx={{ mr: 1 }} />
            <FormattedMessage id="Catalogues.plantProtection.applications.add" />
          </Button>
        )}
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={canUpdate ? columnsCustom : columnsEagri}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />

        {applications.length ? (
          <TableBody>
            {applications.map(
              (application: PlantProtectionDetailRegistrationApplicationTo) => (
                <ApplicationRow
                  application={application}
                  canUpdate={canUpdate}
                  isEditing={isEditing}
                  key={application.id}
                  onDeleteAccept={() => onDeleteAccept(application.id)}
                />
              ),
            )}
          </TableBody>
        ) : (
          <CfTableBodyEmpty
            colLength={
              Object.keys(canUpdate ? columnsCustom : columnsEagri).length + 1
            }
          />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        {showEditDialog && (
          <ApplicationEditDialog
            handleClose={handleEditDialogClose}
            isNew={true}
            opened={showEditDialog}
          />
        )}
      </CfTableWrapper>
    </div>
  );
};

const columnsEagri = {
  actions: getColDesc(false, <span />, { width: "30px" }),
  [APPLICATIONS_COLUMN_NAMES.CROP]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.cropName" />,
    {
      paddingLeft: 16,
    },
  ),
  [APPLICATIONS_COLUMN_NAMES.ORGANISM]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.organismName" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.REG_EXTERNAL_ID]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.registrationExternalId" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.REG_HOLDER]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.registrationHolder" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.REG_NUMBER]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.registrationNumber" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.VALID_UNTIL]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.validUntil" />,
  ),
};

const columnsCustom = {
  actions: getColDesc(false, <span />, { width: "30px" }),
  [APPLICATIONS_COLUMN_NAMES.CROP]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.cropName" />,
    {
      paddingLeft: 16,
    },
  ),
  [APPLICATIONS_COLUMN_NAMES.ORGANISM]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.organismName" />,
  ),
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  addButton: {
    color: theme.palette.common.white,
    padding: "4px 8px",
    borderRadius: "50px",
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));
