import { useEffect, useContext, useState } from "react";

import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { TELEMATICS_URLS } from "../../../telematics.constants";

import { getPreviousTelematicsPage } from "../../../containers/Telematics/helpers";
import { TelematicsContext } from "../../../containers/Telematics/Telematics";
import { useTelematicsAggregationDetailContentStyles } from "../styles";

import {
  Affiliation,
  DriveDetailTo,
} from "../../../../shared/api/telematics/telematics.types";

type Props = {
  driveDetail: DriveDetailTo;
  fetchRelatedDrives: (
    driverCode: string,
    dateFrom: string,
    dateTo: string,
  ) => void;
  fetchRelatedDrivesByMachine: (
    machine: string,
    dateFrom: string,
    dateTo: string,
  ) => void;
};

const useLinkArrows = (props: Props) => {
  const { driveDetail, fetchRelatedDrives, fetchRelatedDrivesByMachine } =
    props;
  const { farmId } = useContext(TelematicsContext);
  const classes = useTelematicsAggregationDetailContentStyles();
  const history = useHistory();
  const location = useLocation();
  const [previousPage, setPreviousPage] = useState<
    keyof typeof TELEMATICS_URLS | undefined
  >(undefined);

  const { dateFrom: df, driver, machine } = driveDetail;
  const dateFrom = moment(df).startOf("day").toISOString();
  const dateTo = moment(df).endOf("day").toISOString();
  const { code: driverCode } = driver;

  useEffect(() => {
    setPreviousPage(getPreviousTelematicsPage(location));
  }, [driveDetail, location, location.pathname]);

  useEffect(() => {
    if (previousPage) {
      if (previousPage === "machines") {
        fetchRelatedDrivesByMachine(
          machine?.name ?? "nomachine",
          dateFrom,
          dateTo,
        );
      } else {
        fetchRelatedDrives(driverCode ?? "nodriver", dateFrom, dateTo);
      }
    }
  }, [
    previousPage,
    fetchRelatedDrives,
    machine?.name,
    dateFrom,
    dateTo,
    driverCode,
    fetchRelatedDrivesByMachine,
  ]);

  const handleBackwardClick = (
    relatedDrives: number[] | null,
    index: number,
  ) => {
    if (index < 1) return;
    history.push(
      `/farm/${farmId}/telematics/${previousPage}/${relatedDrives?.[index - 1]}`,
    );
  };

  const handleForwardClick = (
    relatedDrives: number[] | null,
    index: number,
    count: number,
  ) => {
    if (index >= count - 1) return;
    history.push(
      `/farm/${farmId}/telematics/${previousPage}/${relatedDrives?.[index + 1]}`,
    );
  };

  return {
    farmId,
    classes,
    history,
    dateFrom: moment(driveDetail.dateFrom).startOf("day").toISOString(),
    dateTo: moment(driveDetail.dateFrom).endOf("day").toISOString(),
    driveId: driveDetail.id,
    driverCode: driveDetail.driver.code,
    driverName: driveDetail.driver.name,
    machineName: driveDetail.machine?.name,
    externalDriver: driveDetail.driver.affiliation === Affiliation.EXTERNAL,
    previousPage,
    handleBackwardClick,
    handleForwardClick,
  };
};

export default useLinkArrows;
