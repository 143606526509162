import React from "react";

import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";

import { NAMESPACE as namespace } from "../../../shared/reducer/parcelDetail.reducer";

import { ColumnData } from "../../../../../../catalogues/helpers/tableHelpers";
import { CropName } from "../../../../../../shared/components/specific/CropName/CropName";
import CfTableBodyEmpty from "../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableCell from "../../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableWrapper from "../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableHead from "../../../../../../shared/containers/CfTableHead/CfTableHead";
import { useTypedIntl } from "../../../../../../shared/hooks/useTypedIntl";
import { getColDesc } from "../../../../../../shared/misc/helper";
import Localization from "../../../../../../shared/services/Localization.service";
import { ParcelDetailSection } from "../ParcelDetailSection/ParcelDetailSection";

import { ParcelSeedApplicationTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

const createColumns = (): {
  crop: ColumnData;
  variety: ColumnData;
  period: ColumnData;
  accountableN?: ColumnData;
  application: ColumnData;
  yield: ColumnData;
} => ({
  crop: getColDesc(
    false,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="common.crop" />
    </span>,
  ),
  variety: getColDesc(false, <FormattedMessage id="common.variety" />),
  period: getColDesc(
    false,
    <FormattedMessage id="ParcelDetailOverview.crops.period" />,
  ),
  accountableN: getColDesc(
    false,
    <span>
      <FormattedMessage id="common.accountableN" />
      {" (kg/ha)"}
    </span>,
  ),
  application: getColDesc(
    false,
    <span>
      <FormattedMessage id="ParcelDetailOverview.crops.applied" />
      {" (kg/ha)"}
    </span>,
  ),
  yield: getColDesc(
    false,
    <FormattedMessage id="common.yield" values={{ unit: "(t/ha)" }} />,
  ),
});

type Props = {
  parcelSeedApplication: ParcelSeedApplicationTo[];
  isParcelInZOD: boolean;
};

export const ParcelDetailCropTable = ({
  isParcelInZOD,
  parcelSeedApplication,
}: Props) => {
  const classes = useStyles();
  const intl = useTypedIntl();

  const columns = createColumns();

  if (!isParcelInZOD) {
    delete columns.accountableN;
  }

  return (
    <CfTableWrapper>
      <CfTableHead columns={columns} namespace={namespace} />
      {parcelSeedApplication.length ? (
        <TableBody>
          {parcelSeedApplication.map((item) => {
            const fertilization = item.fertilization;
            return (
              <TableRow key={item.id}>
                <CfTableCell name="name">
                  <CropName
                    className={classes.firstColumn}
                    cropType={item.type}
                  >
                    {item.seed?.crop.name}
                  </CropName>
                </CfTableCell>
                <CfTableCell name="variety">
                  {item.seed?.varietyName ?? "-"}
                </CfTableCell>
                <CfTableCell name="period">
                  {item.startDate ? (
                    <FormattedDate value={item.startDate} />
                  ) : (
                    ""
                  )}
                  {" \u2013 "}
                  {item.endDate ? <FormattedDate value={item.endDate} /> : ""}
                </CfTableCell>
                {isParcelInZOD && (
                  <CfTableCell name="accountableN">
                    {Localization.num2str(
                      fertilization.accountableNitrogen,
                      intl.locale,
                    ) ?? "-"}
                  </CfTableCell>
                )}
                <CfTableCell name="applications">
                  {fertilization ? (
                    <Grid container>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          label="N"
                          valueBold={false}
                          valueItem={Localization.num2str(
                            fertilization.nitrogen,
                            intl.locale,
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          label={
                            <span>
                              P<sub>2</sub>O<sub>5</sub>
                            </span>
                          }
                          valueItem={Localization.num2str(
                            fertilization.p2o5,
                            intl.locale,
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          label={
                            <span>
                              K<sub>2</sub>O
                            </span>
                          }
                          valueItem={Localization.num2str(
                            fertilization.k2o,
                            intl.locale,
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <span />
                  )}
                </CfTableCell>
                <CfTableCell name="yield">
                  {item.primaryRevenueHa
                    ? Localization.num2str(item.primaryRevenueHa, intl.locale)
                    : "-"}
                </CfTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
    </CfTableWrapper>
  );
};

const useStyles = makeStyles(() => ({
  firstColumn: {
    fontWeight: 500,
    paddingLeft: 15,
  },
}));
