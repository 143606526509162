import React, { useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getErrorRules } from "../../../shared/api/automation/rules/rules.selectors";
import {
  getNotificationListSelectedOnPage,
  getNotificationListTextFilter,
} from "../../selectors/notificationList.selectors";

import {
  fetchRules,
  deleteRules,
} from "../../actions/notificationList.actions";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import { NAMESPACE as namespace } from "../../reducer/notificationList.reducer";

import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import TableActionButtons from "../../../shared/components/tables/TableActionButtons/TableActionButtons";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import NotificationsTable from "../NotificationsTable/NotificationsTable";

type Props = {
  langId: LANGUAGE_ID;
  farmId: string;
  history: History;
};

const NotificationList = ({ farmId, history, langId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedOnPage = useSelector(getNotificationListSelectedOnPage);
  const textFilter = useSelector(getNotificationListTextFilter);
  const errorRules = useSelector(getErrorRules);

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  return (
    <CfErrorPage error={errorRules}>
      <div className={classes.wrapper}>
        <PageHeader
          actionButtons={
            <Tooltip
              title={<FormattedMessage id="Notifications.createNotification" />}
            >
              <Fab
                aria-label="create notification"
                className={classes.button}
                color="secondary"
                disabled={false}
                size="medium"
                onClick={() => {
                  history.push(`/farm/${farmId}/notifications/new`);
                }}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="notifications-heading"
              value={<FormattedMessage id="common.notifications" />}
            />
          }
        />
        <div>
          <div className={classes.filtersHeader}>
            <div className={classes.tableActionButtons}>
              <TableActionButtons
                selected={selectedOnPage}
                onDeleteActions={() => {
                  dispatch(deleteRules(selectedOnPage));
                }}
                title={
                  <FormattedMessage id="NotificationList.deleteModalAction" />
                }
              />
            </div>
            <div className={classes.textFilter}>
              <CfTextFilter
                initialValue={textFilter}
                name="notification-list-text-filter"
                namespace={namespace}
                translId="NotificationList.textFilterPlaceholder"
              />
            </div>
          </div>
          <NotificationsTable
            farmId={farmId}
            langId={langId}
            goToNotificationDetail={(notificationId) => {
              history.push(`/farm/${farmId}/notifications/${notificationId}`);
            }}
          />
        </div>
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  filtersHeader: {
    display: "flex",
  },
  textFilter: {
    width: 400,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  tableActionButtons: {},
  button: {
    color: theme.palette.common.white,
  },
}));

export default NotificationList;
