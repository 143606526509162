import React, { FC, useEffect, useCallback } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Fab } from "@mui/material";
import Paper from "@mui/material/Paper";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useLocation, match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getDevices,
  getIsFetchingDevices,
  getDevicesTotalCount,
} from "../../../shared/api/irrigation/devices/devices.selectors";
import {
  getAreaDevicesSelected,
  getAreaDevicesTextFilter,
} from "../../selectors/areaDevices.selectors";

import { editorSetHoveredId } from "../../../core/map/actions/editor/editor.actions";
import { setTableSelected } from "../../../shared/actions/table.actions";
import { fetchArea } from "../../actions/areasList.actions";
import { deleteFromArea } from "../../actions/createOrEditArea.actions";
import { fetchDevices } from "../../actions/devices.actions";
import { setEnlargedVariant } from "../../actions/map.actions";

import { NAMESPACE } from "../../reducer/areaDevices.reducer";

import TableActionButtons from "../../../shared/components/tables/TableActionButtons/TableActionButtons";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { Thunk } from "../../../types";
import { setActivePage, setPrevPage } from "../../actions/commonActions";
import IrrigationDevicesTable from "../../components/IrrigationDevicesTable/IrrigationDevicesTable";

import useIrrigationAreaDetailListStyles from "./styles/useIrrigationAreaDetailList.styles";

import { IrrigationState } from "../../../reducers/irrigation.reducer.types";
import { Device } from "../../../shared/api/irrigation/devices/devices.types";

interface Props {
  deleteFromArea: (
    areaId: string,
    deviceIds: string[],
    handleContentRefresh: () => void,
    handleMapContentRefresh: (areaID: string) => void,
  ) => void;
  devices: Device[];
  editorSetHoveredId: (id: string | null) => void;
  fetchArea: (areaID: string) => void;
  fetchDevices: (
    namespace: string,
    areaId?: string,
    forceStatus?: boolean,
  ) => void;
  isFetching: boolean;
  match: matchType<{ areaId: string }>;
  selectedDevices: string[];
  setActivePage: (pathname: string) => void;
  setEnlargedVariant: (bool: boolean) => void;
  setPrevPage: (pathname: string) => void;
  setSelectedTableItems: (items: string[], namespace: string) => void;
  textFilter: string;
  totalCount: number;
}

const IrrigationAreaDetailList: FC<Props> = ({
  deleteFromArea,
  devices,
  editorSetHoveredId,
  fetchArea,
  fetchDevices,
  isFetching,
  match,
  selectedDevices,
  setActivePage,
  setEnlargedVariant,
  setPrevPage,
  setSelectedTableItems,
  textFilter,
  totalCount,
}) => {
  const classes = useIrrigationAreaDetailListStyles();
  const { areaId } = match.params;
  const { pathname } = useLocation();

  const fetchDevicesForThisArea = useCallback(
    () => fetchDevices(NAMESPACE, areaId),
    [fetchDevices, areaId],
  );

  const handleDeleteActions = () => {
    deleteFromArea(areaId, selectedDevices, fetchDevicesForThisArea, fetchArea);
    setSelectedTableItems([], NAMESPACE);
  };

  useEffect(
    () => {
      fetchDevicesForThisArea();
      fetchArea(areaId);
      setActivePage(pathname);
      setEnlargedVariant(false);

      return () => {
        setPrevPage(pathname);
        setSelectedTableItems([], NAMESPACE);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      fetchDevicesForThisArea();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textFilter],
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.filtersHeader}>
          <div className={classes.actionButtons}>
            <TableActionButtons
              onDeleteActions={handleDeleteActions}
              selected={selectedDevices}
              content={
                <FormattedMessage id="ActionButtons.deleteDeviceFromAreaContent" />
              }
              title={
                <FormattedMessage id="ActionButtons.deleteDeviceFromArea" />
              }
            />
          </div>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="irrigation-devices-text-filter"
              namespace={NAMESPACE}
              translId="IrrigationList.deviceNameOrID"
            />
          </div>
        </div>
        <div className={classes.actionButtons}>
          <Fab
            className={classes.fab}
            onClick={fetchDevicesForThisArea}
            size="small"
            variant="extended"
          >
            <RefreshIcon sx={{ mr: 1 }} />
            <FormattedMessage id="Irrigation.reloadValvesStatus" />
          </Fab>
          <Fab
            className={classes.fabMobile}
            onClick={fetchDevicesForThisArea}
            size="small"
          >
            <RefreshIcon />
          </Fab>
        </div>
      </div>
      <Paper elevation={3} style={{ marginRight: 10 }}>
        <IrrigationDevicesTable
          devices={devices}
          handleContentRefresh={fetchDevicesForThisArea}
          handleHover={editorSetHoveredId}
          isFetching={isFetching}
          namespace={NAMESPACE}
          selectableItems
          totalCount={totalCount}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  devices: getDevices(state),
  isFetching: getIsFetchingDevices(state),
  totalCount: getDevicesTotalCount(state),
  selectedDevices: getAreaDevicesSelected(state),
  textFilter: getAreaDevicesTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      fetchDevices,
      editorSetHoveredId,
      setSelectedTableItems: setTableSelected,
      setActivePage,
      setPrevPage,
      setEnlargedVariant,
      deleteFromArea,
      fetchArea,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IrrigationAreaDetailList);
