import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getAreas,
  getIsFetchingAreas,
  getAreasTotalCount,
} from "../../../../shared/api/irrigation/areas/areas.selectors";
import {
  getAreasOrder,
  getAreasPage,
  getAreasRowsPerPage,
  getAreasOrderBy,
} from "../../../selectors/areas.selectors";
import { getDevicesIdsForEachIrrigationArea } from "../../../selectors/map.selectors";

import {
  editorSetHoveredId,
  editorSetSelected,
} from "../../../../core/map/actions/editor/editor.actions";
import { fetchAreasList } from "../../../actions/areasList.actions";
import { setEnlargedVariant } from "../../../actions/map.actions";

import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import { setActivePage, setPrevPage } from "../../../actions/commonActions";
import EmptyAreas from "../../../components/EmptyAreas/EmptyAreas";

import AreaButtonsHeader from "./components/AreaButtonsHeader";
import AreaListRow from "./components/AreaListRow";

const NAMESPACE = "irrigationAreas";

const irrigationAreasTableColumns = {
  name: {
    sortable: true,
    label: <FormattedMessage id="IrrigationAreaList.area" />,
  },
  deviceCount: {
    sortable: true,
    label: <FormattedMessage id="IrrigationAreaList.deviceCount" />,
  },
  irrigationPlan: {
    sortable: false,
    label: <FormattedMessage id="IrrigationAreaList.irrigationPlan" />,
  },
  buttons: {
    sortable: false,
    label: <AreaButtonsHeader />,
    align: "right",
  },
};

const useStyles = makeStyles({
  container: {
    margin: "15px 25px 15px 15px",
  },
});

const IrrigationAreasList = ({
  areas,
  areasDevicesIds,
  editorSetHoveredId,
  editorSetSelected,
  farmId,
  fetchAreas,
  history,
  isFetching,
  order,
  orderBy,
  page,
  rowsPerPage,
  setActivePage,
  setEnlargedVariant,
  setPrevPage,
  totalCount,
}) => {
  const isEmpty = !isFetching && areas.length === 0;

  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    setEnlargedVariant(false);
    fetchAreas();
    editorSetHoveredId(null);
    editorSetSelected([]);
    setActivePage(pathname);

    return () => {
      setPrevPage(pathname);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAreas();
  }, [fetchAreas, order, orderBy, page, rowsPerPage]);

  if (isEmpty) return <EmptyAreas />;
  return (
    <Box className={classes.container}>
      <CfTableWrapper minWidth={0}>
        <CfTableHead
          columns={irrigationAreasTableColumns}
          namespace={NAMESPACE}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && (
          <CfTableBodyLoader columns={irrigationAreasTableColumns} />
        )}
        <TableBody>
          {areas.map((area) => {
            const areaDevicesIds = areasDevicesIds.find(
              (item) => item.id === area.id,
            );
            return (
              <AreaListRow
                area={area}
                areaDevicesIds={areaDevicesIds}
                editorSetHoveredId={editorSetHoveredId}
                farmId={farmId}
                history={history}
                key={area.id}
              />
            );
          })}
        </TableBody>
        <CfTableFooter
          count={totalCount}
          namespace={NAMESPACE}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </Box>
  );
};

IrrigationAreasList.propTypes = {
  farmId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  fetchAreas: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  areasDevicesIds: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setPrevPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  areas: getAreas(state),
  areasDevicesIds: getDevicesIdsForEachIrrigationArea(state),
  isFetching: getIsFetchingAreas(state),
  totalCount: getAreasTotalCount(state),
  page: getAreasPage(state),
  order: getAreasOrder(state),
  orderBy: getAreasOrderBy(state),
  rowsPerPage: getAreasRowsPerPage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAreas: fetchAreasList,
      setEnlargedVariant,
      editorSetHoveredId,
      editorSetSelected,
      setActivePage,
      setPrevPage,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IrrigationAreasList);
