import React from "react";

import { FormattedNumber } from "react-intl";

type Props = {
  value: number;
  decimalDigits?: number;
};

const CfFormattedNumber = ({ decimalDigits = 3, value, ...rest }: Props) => (
  <FormattedNumber
    {...rest}
    maximumFractionDigits={decimalDigits}
    minimumFractionDigits={decimalDigits}
    value={value}
  />
);

export default CfFormattedNumber;
